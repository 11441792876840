<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="big-title">概况</div>
            <div class="list-box">
                <div class="item">
                    <div class="left">
                        <div class="text">商品总数</div>
                        <div class="num">{{info.goods_num}}</div>
                    </div>
                    <i class="iconfont">&#xe601;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">小邮局总数</div>
                        <div class="num">{{info.courier_num}}</div>
                    </div>
                    <i class="iconfont">&#xe904;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">收件总数</div>
                        <div class="num">{{info.receive_num}}</div>
                    </div>
                    <i class="iconfont">&#xe602;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">寄件总数</div>
                        <div class="num">{{info.mail_num}}</div>
                    </div>
                    <i class="iconfont">&#xe603;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">用户总数</div>
                        <div class="num">{{info.account_num}}</div>
                    </div>
                    <i class="iconfont">&#xe600;</i>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import { dataManageAdminIndex } from '@/config/apis.js'
    export default {
        name: "OverView",
        data() {
            return {
                info: {},
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                dataManageAdminIndex().then((res) => {
                    this.info = res.data
                }).catch(err => {})
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .big-title {
        margin: 20px 20px 0;
        font-size: 20px;
        color: #000;
        font-weight: bold;
        line-height: 1;
    }
    .list-box {
        display: flex;
        justify-content: space-between;
        margin: 20px;
        .item {
            width: 1%;
            flex: 1;
            border: 1px solid #F2F2F2;
            border-radius: 4px;
            padding: 20px;
            display: flex;
            align-items: center;
            .left {
                width: 1%;
                flex: 1;
                .text {
                    color: #999;
                }
                .num {
                    color: #409eff;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 6px;
                }
            }
            .iconfont {
                font-size: 124px;
                transform: rotate(30deg);
                color: #409eff;
                opacity: .08;
            }
        }
        .item + .item {
            margin-left: 20px;
        }
    }
</style>